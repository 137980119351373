import React, { Component } from 'react';

import AddPhoneNumberView from '../components/2fa/AddPhoneNumberView';
import { LoginContext } from '../contexts/LoginContext';

class AddPhoneNumberContainer extends Component {
    render() {
        const {
            errorMessage,
            getUserCountry,
            loading,
            onPhoneCountryChange,
            onPhoneNumberChange,
            onPhoneSendVerificationCode,
            phoneCountry,
            phoneNumberInput,
            phoneNumberIsValid,
            phoneCountryLoaded,
        } = this.context;
        return (
            <AddPhoneNumberView
                errorMessage={errorMessage}
                getUserCountry={getUserCountry}
                loading={loading}
                onPhoneCountryChange={onPhoneCountryChange}
                onPhoneNumberChange={onPhoneNumberChange}
                onSendVerificationCode={onPhoneSendVerificationCode}
                phoneCountry={phoneCountry}
                phoneCountryLoaded={phoneCountryLoaded}
                phoneNumberInput={phoneNumberInput}
                phoneNumberIsValid={phoneNumberIsValid}
            />
        );
    }
}

AddPhoneNumberContainer.contextType = LoginContext;

export default AddPhoneNumberContainer;
