import React, { Component } from 'react';

import VerifyPhoneNumberView from '../components/2fa/VerifyPhoneNumberView';
import { LoginContext } from '../contexts/LoginContext';

class VerifyPhoneNumberContainer extends Component {
    render() {
        const {
            code,
            errorMessage,
            loading,
            onPhoneClearNumber,
            onPhoneVerifyCode,
            onPhoneVerifyCodeChange,
            phoneNumberDisplay,
        } = this.context;
        return (
            <VerifyPhoneNumberView
                code={code}
                errorMessage={errorMessage}
                loading={loading}
                onClearNumber={onPhoneClearNumber}
                onCodeChange={onPhoneVerifyCodeChange}
                onVerifyCode={onPhoneVerifyCode}
                phoneNumber={phoneNumberDisplay}
            />
        );
    }
}

VerifyPhoneNumberContainer.contextType = LoginContext;

export default VerifyPhoneNumberContainer;
