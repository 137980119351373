import React from 'react';

import { browserName } from 'detect-browser';
import { AsYouType, CountryCode } from 'libphonenumber-js';

import { RouteHooksProps, withRouteHooks } from 'components/others/withRouteHooks';

import { executeCaptcha } from 'helpers/CaptchaUtils';
import { logError } from 'helpers/tracking/ErrorTracking';

import { LoginContextActions, LoginContextState, TwoFactorProvider } from '../types/LoginContext';
import { get, jsonPost, makeUrl, post } from '../utils/LoginApiUtils';

export const LoginContext = React.createContext<(LoginContextState & LoginContextActions) | null>(
    null,
);

class LoginProvider extends React.Component<
    RouteHooksProps & { children?: React.ReactNode },
    LoginContextState
> {
    state = {
        browser: browserName(navigator.userAgent),
        configureUser: false,
        confirmPassword: '',
        email: '',
        errorMessage: '',
        loading: false,
        loginFailed: false,
        loginPassword: '',
        loginProvidersLoaded: false,
        loginShowPassword: false,
        newPassword: '',
        phoneCountry: 'US' as CountryCode,
        phoneCountryLoaded: false,
        phoneNumber: '',
        phoneNumberDisplay: '',
        phoneNumberInput: '',
        phoneNumberIsValid: false,
        phoneVerifyCode: '',
        phoneVerifyCodeError: false,
        recaptchaFailed: false,
        requireTwoFactor: false,
        resetPasswordBreachWarning: false,
        resetPasswordCode: '',
        resetPasswordEmailSent: false,
        resetPasswordErrorCode: 0,
        resetPasswordFailed: false,
        resetPasswordInvalidLink: false,
        resetPasswordSuccess: false,
        sendPhoneVerificationCodeError: false,
        ssoProviders: [],
        twoFactorActiveProvider: '',
        twoFactorCode: '',
        twoFactorProviders: [],
        twoFactorRememberBrowser: false,
        twoFactorSendCodeError: false,
        publicSsoProviders: window.PUBLIC_SSO_PROVIDERS,
        forceSsoLogin: false,
    };

    genericError =
        'We were not able to process your request, please try again or refresh the page. If the problem persists, please contact support.';

    render() {
        const { children } = this.props;
        return (
            <LoginContext.Provider
                value={{
                    ...this.state,
                    clearErrorMessage: this.clearErrorMessage,
                    getLoginProviders: this.getLoginProviders,
                    getTwoFactorProviders: this.getTwoFactorProviders,
                    getUserCountry: this.getUserCountry,
                    onConfirmPasswordChange: this.onConfirmPasswordChange,
                    onContinueToSalesScreen: this.onContinueToSalesScreen,
                    onEmailChange: this.onEmailChange,
                    onForgotPassword: this.onForgotPassword,
                    onNewPasswordChange: this.onNewPasswordChange,
                    onPasswordChange: this.onPasswordChange,
                    onPhoneClearNumber: this.onPhoneClearNumber,
                    onPhoneCountryChange: this.onPhoneCountryChange,
                    onPhoneNumberChange: this.onPhoneNumberChange,
                    onPhoneSendVerificationCode: this.onPhoneSendVerificationCode,
                    onPhoneVerifyCode: this.onPhoneVerifyCode,
                    onPhoneVerifyCodeChange: this.onPhoneVerifyCodeChange,
                    onResetPassword: this.onResetPassword,
                    onResetPasswordForm: this.onResetPasswordForm,
                    onSignIn: this.onSignIn,
                    onSwitchAccount: this.onSwitchAccount,
                    onTwoFactorCodeChange: this.onTwoFactorCodeChange,
                    onTwoFactorSelectProvider: this.onTwoFactorSelectProvider,
                    onTwoFactorSendCode: this.onTwoFactorSendCode,
                    onTwoFactorToggleRememberBrowser: this.onTwoFactorToggleRememberBrowser,
                    onVerifyTwoFactorCode: this.onVerifyTwoFactorCode,
                    resetForgotPasswordEmailSent: this.resetForgotPasswordEmailSent,
                    validateResetPasswordToken: this.validateResetPasswordToken,
                }}
            >
                {children}
            </LoginContext.Provider>
        );
    }

    onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        this.setState({
            email: email,
            errorMessage: '',
            loginFailed: false,
        });
    };

    onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        this.setState({
            errorMessage: '',
            loginFailed: false,
            loginPassword: password,
        });
    };

    onSwitchAccount = () => {
        this.setState({
            email: '',
            errorMessage: '',
            loginFailed: false,
            loginPassword: '',
            loginProvidersLoaded: false,
            loginShowPassword: false,
            ssoProviders: [],
        });
    };

    onNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        this.setState({
            newPassword: password,
            resetPasswordFailed: false,
        });
    };

    onConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        this.setState({
            confirmPassword: password,
            resetPasswordFailed: false,
        });
    };

    getLoginProviders = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        const email = this.state.email;
        //@ts-ignore
        const returnUrl = window.RETURN_URL;
        if (!email) {
            this.setState({
                errorMessage: 'Please provide a valid email address',
                loginFailed: true,
            });
            return;
        }

        this.setState({ loading: true, errorMessage: '' });

        const url = makeUrl('Login', 'GetLoginProviders');
        const params = {
            //__RequestVerificationToken: this.getAntiForgeryToken(),
            email: email,
            returnUrl: returnUrl,
        };
        post(url, params)
            .redirects(0)
            .ok((res) => res.status < 400)
            .then(this.handleLoginProvidersResponse, this.handleError);
    };

    handleLoginProvidersResponse = (res) => {
        if (res.headers.location) {
            window.location = res.headers.location;
        } else {
            this.setState({
                loading: false,
                loginProvidersLoaded: true,
                loginShowPassword: res.body.password,
                ssoProviders: res.body.ssoProviders,
            });
        }
    };

    onSignIn = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        executeCaptcha('login', (token) => {
            this.setState({ errorMessage: '', loading: true });
            const { email, loginPassword } = this.state;
            //@ts-ignore
            const returnUrl = window.RETURN_URL;
            const url = makeUrl('Login', 'Login');
            const params = {
                //__RequestVerificationToken: this.getAntiForgeryToken(),
                captchaToken: token,
                password: loginPassword,
                returnUrl: returnUrl,
                userName: email,
            };
            post(url, params).then(this.handleSignInResponse, this.handleError);
        });
    };

    handleSignInResponse = (res) => {
        const { errorMessage, recaptchaFailed, requireVerification, returnUrl, success } = res.body;

        if (success) {
            if (requireVerification) {
                this.setState({ loading: false });
                this.props.navigate('/send-code');
            } else {
                window.location.href = returnUrl;
            }
        } else {
            if (recaptchaFailed) {
                this.setState({
                    loading: false,
                    recaptchaFailed: true,
                    twoFactorActiveProvider: 'Email',
                });
                this.props.navigate('/verify-code');
            } else {
                this.setState({
                    errorMessage: errorMessage,
                    loading: false,
                    loginFailed: true,
                });
            }
        }
    };

    onForgotPassword = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        executeCaptcha('forgot_password', (token) => {
            this.setState({ errorMessage: '', loading: true });
            const url = makeUrl('Login', 'ForgotPassword');
            const params = {
                //__RequestVerificationToken: this.getAntiForgeryToken(),
                captchaToken: token,
                email: this.state.email,
            };
            post(url, params).then(this.handleForgotPasswordResponse, this.handleError);
        });
    };

    handleForgotPasswordResponse = (res) => {
        if (res.body.success) {
            this.setState({
                loading: false,
                resetPasswordEmailSent: true,
                errorMessage: '',
            });
        } else {
            this.setState({
                loading: false,
                resetPasswordEmailSent: false,
                errorMessage: res.body.errorMessage,
            });
        }
    };

    onResetPassword = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        const {
            confirmPassword,
            email,
            newPassword,
            resetPasswordBreachWarning,
            resetPasswordCode,
        } = this.state;
        if (newPassword !== confirmPassword) {
            this.setState({
                errorMessage: "The passwords don't match",
                resetPasswordFailed: true,
            });
            return;
        }

        if (!this.validatePassword(newPassword)) return;

        this.setState({ errorMessage: '', loading: true });
        const url = makeUrl('Login', 'ResetPassword');
        const params = {
            code: resetPasswordCode,
            confirmPassword: confirmPassword,
            ignoreBreachWarning: resetPasswordBreachWarning,
            email: email,
            password: newPassword,
        };

        post(url, params).then(this.handleResetPasswordResponse, this.handleError);
    };

    onResetPasswordForm = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        this.setState({
            confirmPassword: '',
            newPassword: '',
            resetPasswordBreachWarning: false,
        });
    };

    handleResetPasswordResponse = (res) => {
        const {
            configure,
            errorCode,
            errorMessage,
            passwordBreachWarning,
            requireVerification,
            success,
        } = res.body;
        this.setState({ loading: false });
        if (success) {
            this.setState({
                configureUser: configure,
                requireTwoFactor: requireVerification,
                resetPasswordSuccess: true,
            });
        } else {
            this.setState({
                errorMessage: errorMessage,
                resetPasswordBreachWarning: passwordBreachWarning,
                resetPasswordFailed: true,
                resetPasswordInvalidLink: errorCode === 2,
            });
        }
    };

    validateResetPasswordToken = (email: string, token: string) => {
        this.setState({ email, errorMessage: '', loading: true });

        executeCaptcha('forgot_password', (captchaToken) => {
            const url = makeUrl('Login', 'ValidateResetPasswordToken');
            const params = {
                captchaToken,
                email,
                token,
            };
            post(url, params).then(
                ({
                    body: { success, forceSsoLogin, allowedSsoProviders, errorMessage, errorCode },
                }) => {
                    this.setState({ loading: false });
                    if (success) {
                        this.setState({
                            resetPasswordCode: token,
                            forceSsoLogin,
                            ssoProviders: allowedSsoProviders,
                        });
                    } else {
                        this.setState({
                            resetPasswordFailed: true,
                            errorMessage: errorMessage,
                            resetPasswordInvalidLink: errorCode === 2,
                        });
                    }
                },
                this.handleError,
            );
        });
    };

    validatePassword = (password: string) => {
        let errorMessage = '';
        if (password.length < 12) {
            errorMessage = 'Password must be at least 12 characters long';
        }

        this.setState({ errorMessage: errorMessage });
        if (errorMessage) {
            this.setState({ resetPasswordFailed: true });
            return false;
        }

        return true;
    };

    onTwoFactorSelectProvider = (provider: TwoFactorProvider) => {
        this.setState({
            twoFactorActiveProvider: provider,
        });
    };

    getTwoFactorProviders = () => {
        this.setState({ loading: true });
        const url = makeUrl('TwoFactor', 'GetTwoFactorProviders');
        get(url).then(this.handleTwoFactorProvidersResponse, this.handleError);
    };

    handleTwoFactorProvidersResponse = (res) => {
        if (res.body.success) {
            this.setState({
                loading: false,
                twoFactorProviders: res.body.providers,
            });
        } else {
            const error = res.body.invalidCookie
                ? 'Your sessions has expired, please go back to the login page and restart the login process'
                : this.genericError;
            this.setState({
                errorMessage: error,
                loading: false,
            });
        }
    };

    onTwoFactorSendCode = () => {
        this.setState({
            loading: true,
            twoFactorSendCodeError: false,
        });
        const { twoFactorActiveProvider } = this.state;
        const url = makeUrl('TwoFactor', 'SendCode');
        const params = {
            provider: twoFactorActiveProvider,
        };

        post(url, params).then(this.handleTwoFactorSendCodeResponse, this.handleError);
    };

    handleTwoFactorSendCodeResponse = (res) => {
        if (res.body.success) {
            this.setState({ loading: false });
            this.props.navigate('/verify-code');
        } else {
            this.setState({
                errorMessage: res.body.errorMessage,
                loading: false,
                twoFactorSendCodeError: true,
            });
        }
    };

    onTwoFactorCodeChange = (e) => {
        const code = e.target.value;
        this.setState({
            errorMessage: '',
            twoFactorCode: code,
        });
    };

    onTwoFactorToggleRememberBrowser = () => {
        const currentValue = this.state.twoFactorRememberBrowser;
        this.setState({
            twoFactorRememberBrowser: !currentValue,
        });
    };

    onVerifyTwoFactorCode = () => {
        this.setState({ errorMessage: '', loading: true });
        const { twoFactorActiveProvider, twoFactorCode, twoFactorRememberBrowser } = this.state;
        //@ts-ignore
        const returnUrl = window.RETURN_URL;
        const url = makeUrl('TwoFactor', 'VerifyCode');
        const params = {
            code: twoFactorCode,
            provider: twoFactorActiveProvider,
            rememberBrowser: twoFactorRememberBrowser,
            returnUrl: returnUrl,
        };

        post(url, params).then(this.handleTwoFactorVerifyCodeResponse, this.handleError);
    };

    handleTwoFactorVerifyCodeResponse = (res) => {
        const { lockedOut, returnUrl, success } = res.body;
        if (success) {
            window.location.href = returnUrl;
        } else {
            const error = lockedOut
                ? 'Your account is temporarily locked as a result of too many failed login attempts. Please try again in a few minutes.'
                : 'The code you have entered is not valid';
            this.setState({
                errorMessage: error,
                loading: false,
            });
        }
    };

    getUserCountry = () => {
        const url = makeUrl('TwoFactor', 'GetUserCountry');
        get(url).then(this.handleUserCountryResponse, this.handleError);
    };

    handleUserCountryResponse = (res) => {
        const { countryIsoCode, success } = res.body;
        if (success) {
            this.setState({ phoneCountry: countryIsoCode });
        }
    };

    onPhoneCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const phoneCountry = e.target.value as CountryCode;
        const asYouType = new AsYouType(phoneCountry);
        const inputNumber = asYouType.input(this.state.phoneNumberDisplay);
        const phoneNumber = asYouType.getNumber();
        this.setState({
            phoneCountry: phoneCountry,
            phoneNumber: phoneNumber ? (phoneNumber.number as string) : '',
            phoneNumberDisplay: phoneNumber ? phoneNumber.formatInternational() : '',
            phoneNumberInput: inputNumber,
            phoneNumberIsValid: phoneNumber ? phoneNumber.isValid() : false,
        });
    };

    onPhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let phoneNumberInput = e.target.value;
        if (phoneNumberInput.indexOf('(') > -1 && phoneNumberInput.indexOf(')') === -1) {
            phoneNumberInput = phoneNumberInput.slice(0, -1);
        }
        const asYouType = new AsYouType(this.state.phoneCountry);
        const inputNumber = asYouType.input(phoneNumberInput);
        const phoneNumber = asYouType.getNumber();
        this.setState({
            phoneNumber: phoneNumber ? (phoneNumber.number as string) : '',
            phoneNumberDisplay: phoneNumber ? phoneNumber.formatInternational() : '',
            phoneNumberInput: inputNumber,
            phoneNumberIsValid: phoneNumber ? phoneNumber.isValid() : false,
        });
    };

    onPhoneSendVerificationCode = () => {
        this.setState({
            loading: true,
        });
        const { phoneNumber } = this.state;
        const url = makeUrl('TwoFactor', 'AddPhoneNumber');
        const params = {
            number: phoneNumber,
        };

        jsonPost(url, params).then(this.handlePhoneSendVerificationCodeResponse, this.handleError);
    };

    handlePhoneSendVerificationCodeResponse = (res) => {
        if (res.body.success) {
            this.setState({ loading: false });
            this.props.navigate('/verify-phone-number');
        } else {
            this.setState({
                errorMessage: 'Unable to send verification code',
                loading: false,
            });
        }
    };

    onPhoneClearNumber = () => {
        this.setState({
            phoneNumber: '',
            phoneNumberDisplay: '',
            phoneNumberInput: '',
            phoneVerifyCode: '',
            phoneVerifyCodeError: false,
        });
        this.props.navigate(-1);
    };

    onPhoneVerifyCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            phoneVerifyCode: e.target.value,
        });
    };

    onPhoneVerifyCode = () => {
        this.setState({
            loading: true,
            sendPhoneVerificationCodeError: false,
        });
        const { phoneNumber, phoneVerifyCode } = this.state;
        const url = makeUrl('TwoFactor', 'VerifyPhoneNumber');
        const params = {
            code: phoneVerifyCode,
            enableTwoFactor: true,
            phoneNumber,
        };

        jsonPost(url, params).then(this.handlePhoneVerifyCodeResponse, this.handleError);
    };

    handlePhoneVerifyCodeResponse = (res) => {
        if (res.body.success) {
            this.setState({ loading: false });
            this.props.navigate('/phone-success');
        } else {
            this.setState({
                errorMessage: res.body.error,
                loading: false,
                phoneVerifyCodeError: true,
            });
        }
    };

    getAntiForgeryToken = () => {
        const form = document.getElementById('__AjaxAntiForgeryForm') as HTMLFormElement;
        return form.elements['__RequestVerificationToken'].value;
    };

    handleError = (error) => {
        console.log(error); //eslint-disable-line
        this.setState({
            errorMessage: this.genericError,
            loading: false,
        });

        // Send error and error info to Raygun
        logError(error);
    };

    onContinueToSalesScreen = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();

        if (this.state.requireTwoFactor) {
            this.props.navigate('/send-code');
        } else if (this.state.configureUser) {
            window.location.replace(`${window.SALESSCREEN_WEB_URL}/?configure=true`);
        } else {
            window.location.replace(window.SALESSCREEN_WEB_URL);
        }
    };

    resetForgotPasswordEmailSent = () => {
        this.setState({
            resetPasswordEmailSent: false,
        });
    };

    clearErrorMessage = () => {
        this.setState({
            errorMessage: '',
        });
    };
}

export default withRouteHooks(LoginProvider);
