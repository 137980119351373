import React, { useEffect } from 'react';

import { Button } from 'components/buttons/Button';
import { Loading } from 'components/loadings/Loading';

import { ErrorMessageBox } from '../common/ErrorMessageBox';
import InputText from '../common/InputText';
import { TextContentBox } from '../common/TextContentBox';

import CountryCodeSelect from './CountryCodeSelect';

const AddPhoneNumberView = ({
    errorMessage,
    getUserCountry,
    loading,
    onPhoneCountryChange,
    onPhoneNumberChange,
    onSendVerificationCode,
    phoneCountry,
    phoneCountryLoaded,
    phoneNumberInput,
    phoneNumberIsValid,
}) => {
    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSendVerificationCode();
        }
    };

    useEffect(() => {
        if (!phoneCountryLoaded) {
            getUserCountry();
        }
    }, [getUserCountry, phoneCountryLoaded]);

    return (
        <div className="loginActionsColumn">
            <div className="loginActions twoFactorAuthentication">
                <div className="twoFactorContentContainer">
                    <TextContentBox
                        title="Two-factor Authentication"
                        paragraph1="Your admin has required that you set up this account for additional security verification. To do so, you need to add and verify your phone number."
                        paragraph2="Once you have entered a phone number, you will receive a verification code sent by SMS. Enter this code to verify the new number."
                    />
                    <div className="twoFactorInputContainer">
                        <div className="phoneInputContainer">
                            <CountryCodeSelect
                                activeCountry={phoneCountry}
                                onChange={onPhoneCountryChange}
                            />
                            <div className="phoneNumberWrapper">
                                <InputText
                                    autoFocus={true}
                                    label="Phone Number"
                                    onChange={onPhoneNumberChange}
                                    onKeyPress={onKeyPress}
                                    placeholder="Enter your phone number"
                                    type="tel"
                                    value={phoneNumberInput}
                                />
                            </div>
                        </div>
                    </div>
                    <Button
                        disabled={!phoneNumberIsValid || loading}
                        onClick={onSendVerificationCode}
                        text="Send Verification Code"
                    />
                    {errorMessage && !loading && <ErrorMessageBox errorMessage={errorMessage} />}
                    {loading && (
                        <div className="loading-wrapper -tw-bottom-[100px]">
                            <Loading />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddPhoneNumberView;
